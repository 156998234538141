<template>
  <div>
    <div v-if="!clientInfoApiDataIsLoading">
      <div class="row">
        <div class="col-12" v-if="clientInfoApiDataErrorMessage != null">
          <div class="alert alert-danger">
            {{clientInfoApiDataErrorMessage}}
          </div>
        </div>
        <div class="col-12" v-if="clientStatementApiDataErrorMessage != null">
          <div class="alert alert-danger">
            {{clientStatementApiDataErrorMessage}}
          </div>
        </div>
      </div>
      <div v-if="clientInfoApiData != null">
        <div class="row">
          <div class="col">
            <div class="profile">
              <div class="profile-header">
                <!-- BEGIN profile-header-cover -->
                <div class="profile-header-cover"></div>
                <!-- END profile-header-cover -->
                <!-- BEGIN profile-header-content -->
                <div class="profile-header-content">
                  <!-- BEGIN profile-header-img -->
                  <div class="profile-header-img">
                    <img src="/assets/img/user/no-user.jpeg" alt="">
                  </div>
                  <!-- END profile-header-img -->
                  <!-- BEGIN profile-header-info -->
                  <div class="profile-header-info">
                    <h1 class="m-t-10 m-b-5">{{ clientInfoApiData.name }}</h1>
                    <h3 class="m-b-5">{{ clientInfoApiData.erp_code }} |
                      {{ clientInfoApiData.group_name }} |
                      {{ clientInfoApiData.location_name }}</h3>
                    <h3 class="m-b-5">{{ clientInfoApiData.phone }}</h3>
                    <!--<a href="#" class="btn btn-xs btn-yellow">Edit Profile</a>-->
                  </div>
                  <!-- END profile-header-info -->
                </div>
                <!-- END profile-header-content -->
                <!-- BEGIN profile-header-tab -->
                <ul class="profile-header-tab nav nav-tabs">
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('about')"
                                          v-bind:class="{ 'active': tab.about }" class="nav-link"
                                          data-toggle="tab">INFO. CLIENT</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('orders')"
                                          v-bind:class="{ 'active': tab.orders }" class="nav-link"
                                          data-toggle="tab">FACTURES</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('unpaid')"
                                          v-bind:class="{ 'active': tab.unpaid }" class="nav-link"
                                          data-toggle="tab">IMPAYÉES</a></li>
                  <li class="nav-item"><a href="javascript:;" v-on:click="show('statement')"
                                          v-bind:class="{ 'active': tab.statement }" class="nav-link"
                                          data-toggle="tab">RELEVÉ DE COMPTE</a></li>
                </ul>
                <!-- END profile-header-tab -->
              </div>
            </div>
            <div class="profile-content">
              <!-- begin tab-content -->
              <div class="tab-content p-0">
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.about }">
                  <div class="form-group row">
                    <div class="col-xs-7">
                      <!-- begin table -->
                      <div class="table-responsive form-inline">
                        <table class="table table-profile">
                          <thead>
                          <tr>
                            <th></th>
                            <th>
                              <h4>{{ clientInfoApiData.name }}
                                <small>{{ clientInfoApiData.erp_code }} |
                                  {{ clientInfoApiData.group_name }} |
                                  {{ clientInfoApiData.location_name }}
                                </small>
                              </h4>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr class="highlight">
                            <td class="field valign-middle">Contact & Adresse</td>
                            <td><a href="javascript:;"></a></td>
                          </tr>
                          <tr class="divider">
                            <td colspan="2"></td>
                          </tr>
                          <tr>
                            <td class="field valign-middle">Téléphone</td>
                            <td><i class="fa fa-mobile fa-lg m-r-5"></i>
                              {{ clientInfoApiData.phone }}
                            </td>
                          </tr>
                          <tr>
                            <td class="field valign-middle">Autres Contacts</td>
                            <td><i class="fa fa-mobile fa-lg m-r-5"></i>
                              {{ clientInfoApiData.contact_phones }}
                            </td>
                          </tr>
                          <tr>
                            <td class="field valign-middle">Adresse</td>
                            <td>{{ clientInfoApiData.address_line }},
                              {{ clientInfoApiData.sector_name }}
                            </td>
                          </tr>
                          <tr>
                            <td class="field valign-middle">Localité</td>
                            <td><a href="javascript:;">{{ clientInfoApiData.cluster_name }},
                              {{ clientInfoApiData.location_name }}</a></td>
                          </tr>
                          <tr class="divider">
                            <td colspan="2"></td>
                          </tr>
                          <tr class="highlight">
                            <td class="field valign-middle">Zoning & Segmentation</td>
                            <td><a href="javascript:;"></a></td>
                          </tr>
                          <tr class="divider">
                            <td colspan="2"></td>
                          </tr>
                          <tr>
                            <td class="field valign-middle">Activité</td>
                            <td><a href="javascript:;">{{ clientInfoApiData.type_name }}</a>
                            </td>
                          </tr>
                          <tr>
                            <td class="field valign-middle">Segmentation</td>
                            <td><a href="javascript:;">{{ clientInfoApiData.commercial_zone_name }}</a>
                            </td>
                          </tr>
                          <tr>
                            <td class="field valign-middle">Zone</td>
                            <td><a href="javascript:;">{{ clientInfoApiData.commercial_cluster_name }}</a>
                            </td>
                          </tr>
                          <tr>
                            <td class="field valign-middle">Secteur</td>
                            <td><a href="javascript:;">{{ clientInfoApiData.commercial_sector_name }}</a>
                            </td>
                          </tr>
                          <tr class="divider">
                            <td colspan="2"></td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- end table -->
                    </div>
                    <div class="col-xs-5">
                      <!-- begin widget-map -->
                      <div class="widget-map widget-map-rounded m-b-30">
                        <!-- begin widget-input-container -->
                        <div class="widget-input-container">
                          <div class="widget-input-icon"><a href="javascript:;"
                                                            class="text-inverse"
                                                            data-id="widget-elm"
                                                            data-light-class="text-inverse"
                                                            data-dark-class="text-white"><i
                              class="fa fa-ellipsis-v"></i></a></div>
                          <div class="widget-input-box">
                            <input type="text" class="form-control form-control-sm"
                                   placeholder="Recherche rapide">
                          </div>
                          <div class="widget-input-icon"><a href="javascript:;"
                                                            class="text-inverse"
                                                            data-id="widget-elm"
                                                            data-light-class="text-inverse"
                                                            data-dark-class="text-white"><i
                              class="fa fa-microphone"></i></a></div>
                          <div class="widget-input-divider"></div>
                          <div class="widget-input-icon"><a href="javascript:;"><i
                              class="fa fa-location-arrow"></i></a></div>
                        </div>
                        <!-- end widget-input-container -->
                        <!-- begin widget-map-body -->

                        <div class="widget-map-body">
                          <GmapMap ref="mapRef"
                                   style="height: 400px;"
                                   class="width-full"
                                   v-bind:options="map_options"
                                   :zoom="14"
                                   :center="{lat:clientInfoApiData.lat, lng:clientInfoApiData.lng}">


                            <GmapMarker
                                :position="{lat:clientInfoApiData.lat, lng:clientInfoApiData.lng}"
                                :clickable="true"/>

                            <!--
                            <GmapInfoWindow
                                    @closeclick="show_prospect_info_window=false"
                                    :opened="show_prospect_info_window"
                                    :position="{lat:selected_prospect.lat, lng:selected_prospect.lng}"
                                    :options="{pixelOffset: {width: 0, height: -40}}">
                                <div class="card">
                                    <div class="card-block">
                                        <h4 class="card-title m-t-0">{{selected_prospect.name}}</h4>
                                        <h6 class="card-subtitle m-b-10 text-muted">{{selected_prospect.phone}}</h6>
                                        <p class="card-text">{{selected_prospect.address}}</p>
                                    </div>
                                </div>
                            </GmapInfoWindow>
                            -->

                          </GmapMap>
                        </div>

                        <!-- end widget-map-body -->
                        <!-- begin widget-map-list -->
                        <div class="widget-map-list">
                          <div class="widget-list widget-list-rounded m-b-30">
                            <!-- begin widget-list-item -->
                            <div class="widget-list-item">
                              <div class="widget-list-media text-center">
                                <a href="javascript:;"><i
                                    class="fas fa-map-marker-alt fa-2x"></i></a>
                              </div>
                              <div class="widget-list-content">
                                <h4 class="widget-list-title">
                                  {{ clientInfoApiData.name }}</h4>
                                <p class="widget-list-desc">
                                  {{ clientInfoApiData.cluster_name }},
                                  {{ clientInfoApiData.location_name }}</p>
                              </div>
                              <div class="widget-list-action">
                                <a href="#" data-toggle="dropdown"
                                   class="text-muted pull-right"><i
                                    class="fa fa-angle-right fa-2x"></i></a>
                              </div>
                            </div>
                            <!-- end widget-list-item -->
                          </div>
                        </div>
                        <!-- end widget-map-list -->
                      </div>
                      <!-- end widget-map -->
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.orders }">
                  <div class="invoice">
                    <!-- begin invoice-company -->
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                            </span>
                      <h1>Factures Client</h1>
                    </div>
                    <!-- end invoice-company -->
                    <!-- begin invoice-header -->
                    <div class="invoice-header">
                      <div class="invoice-from">
                        <small>De chez</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ clientInfoApiData.company_name }}</strong><br/>
                          {{ clientInfoApiData.company_address_line }}<br/>
                          {{ clientInfoApiData.company_cluster_name }},
                          {{ clientInfoApiData.company_location_name }}<br/>
                          Téléphone: {{ clientInfoApiData.company_phone }}<br/>
                        </address>
                      </div>
                      <div class="invoice-to">
                        <small>Au client</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ clientInfoApiData.erp_code }} |
                            {{ clientInfoApiData.name }}</strong><br/>
                          {{ clientInfoApiData.address_line }}<br/>
                          {{ clientInfoApiData.cluster_name }},
                          {{ clientInfoApiData.location_name }}<br/>
                          Téléphone: {{ clientInfoApiData.phone }}<br/>
                        </address>
                      </div>
                    </div>

                    <!-- end invoice-header -->
                    <!-- begin invoice-content -->
                    <div class="invoice-content">
                      <!-- begin table-responsive -->
                      <div class="table-responsive">
                        <vue-good-table
                            :columns="[
                                                        {
                                                            label: 'Date',
                                                            field: 'formatted_creation_date',
                                                            thClass: 'text-nowrap',
                                                            tdClass: 'text-nowrap'
                                                        },
                                                        {
                                                            label: 'Réf.',
                                                            field: 'server_side_code',
                                                            thClass: 'text-nowrap',
                                                            tdClass: 'text-nowrap'
                                                        },
                                                        {
                                                            label: 'Montant',
                                                            field: 'order_amount_ttc',
                                                            thClass: 'text-nowrap',
                                                            tdClass: 'text-nowrap'
                                                        },
                                                        ]"
                            :rows="clientInfoApiData.sales_orders"
                            @on-row-click="onSalesOrderRowClick"
                            :lineNumbers="true"
                            :search-options="{ enabled: true, placeholder: 'Recherche rapide'}"
                            :pagination-options="{
                                                            enabled: true,
                                                            position: 'bottom',
                                                            nextLabel: 'Suiv.',
                                                            prevLabel: 'Préc.',
                                                            rowsPerPageLabel: 'Entrées par page',
                                                            ofLabel: 'de',
                                                            pageLabel: 'page',
                                                            allLabel: 'Tous',

                                                         }"
                            :selectOptions="{
                                                            enabled: false,
                                                            selectOnCheckboxOnly: true,
                                                            selectionInfoClass: 'alert alert-green m-b-0',
                                                            selectionText: 'Entrées sélectionnées',
                                                            clearSelectionText: 'Annuler la sélection',
                                                        }"
                        >
                          <div slot="selected-row-actions" style="margin: -2px 0;">
                            <button class="btn btn-xs btn-primary m-r-5">Action 1</button>
                            <button class="btn btn-xs btn-grey">Action 2</button>
                          </div>
                          <!--
                          <template slot="table-row" slot-scope="props">
                              <span v-if="props.column.field == 'image'">
                                  <img v-bind:src="props.row.image" class="rounded" width="24" style="margin: -3px" />
                              </span>
                                                          <span v-else>
                                  {{props.formattedRow[props.column.field]}}
                              </span>
                          </template>
                          -->
                        </vue-good-table>
                      </div>
                    </div>
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ clientInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ clientInfoApiData.company_phone }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ clientInfoApiData.company_email }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.unpaid }">
                  <div class="invoice">
                    <!-- begin invoice-company -->
                    <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                                <a :href="clientInfoApiData.document_url_pdf" target="_blank"
                                                   class="btn btn-sm btn-white m-b-10 mr-2"
                                                   v-if="clientInfoApiData.document_url_pdf != null"><i
                                                    class="fa fa-file-pdf text-danger fa-fw fa-lg"></i> PDF</a>
                                            </span>
                      <h1>Factures impayées</h1>
                    </div>
                    <!-- end invoice-company -->
                    <!-- begin invoice-header -->
                    <div class="invoice-header">
                      <div class="invoice-from">
                        <small>Impayées à</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ clientInfoApiData.company_name }}</strong><br/>
                          {{ clientInfoApiData.company_address_line }}<br/>
                          {{ clientInfoApiData.company_cluster_name }},
                          {{ clientInfoApiData.company_location_name }}<br/>
                          Téléphone: {{ clientInfoApiData.company_phone }}<br/>
                        </address>
                      </div>
                      <div class="invoice-to">
                        <small>Par le client</small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse">{{ clientInfoApiData.erp_code }} |
                            {{ clientInfoApiData.name }}</strong><br/>
                          {{ clientInfoApiData.address_line }}<br/>
                          {{ clientInfoApiData.cluster_name }},
                          {{ clientInfoApiData.location_name }}<br/>
                          Téléphone: {{ clientInfoApiData.phone }}<br/>
                        </address>
                      </div>
                    </div>

                    <!-- end invoice-header -->
                    <!-- begin invoice-content -->
                    <div class="invoice-content">
                      <!-- begin table-responsive -->
                      <div class="table-responsive">
                        <table class="table table-invoice">
                          <thead>
                          <tr>
                            <th width="15%">REF.</th>
                            <th class="text-center" width="10%">AGE EN JOURS</th>
                            <th class="text-center" width="15%">0 - 1 Jours</th>
                            <th class="text-center" width="15%">2 - 3 Jours</th>
                            <th class="text-center" width="15%">4 - 5 Jours</th>
                            <th class="text-center" width="15%">6 - 7 Jours</th>
                            <th class="text-center" width="15%">+ de 7 Jours</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="inv in clientInfoApiData.transactions_0_14" :key="inv.id">
                            <td>
                              <b><a href="javascript:;"
                                    @click="onSalesOrderClick(inv)">{{ inv.server_side_code }}</a></b>
                              <br/>
                              <small class="text-inverse">du
                                {{ inv.formatted_creation_date }}
                              </small>
                            </td>
                            <td class="text-center">{{ inv.day_count }}</td>
                            <td class="text-center">
                              {{ inv.order_unpaid_amount|formatMoney }}
                            </td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                          </tr>
                          <tr v-for="inv in clientInfoApiData.transactions_15_30"
                              :key="inv.id">
                            <td>
                              <b><a href="javascript:;"
                                    @click="onSalesOrderClick(inv)">{{ inv.server_side_code }}</a></b>
                              <br/>
                              <small class="text-inverse">du
                                {{ inv.formatted_creation_date }}
                              </small>
                            </td>
                            <td class="text-center">{{ inv.day_count }}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">
                              {{ inv.order_unpaid_amount|formatMoney }}
                            </td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                          </tr>
                          <tr v-for="inv in clientInfoApiData.transactions_31_60"
                              :key="inv.id">
                            <td>
                              <b><a href="javascript:;"
                                    @click="onSalesOrderClick(inv)">{{ inv.server_side_code }}</a></b>
                              <br/>
                              <small class="text-inverse">du
                                {{ inv.formatted_creation_date }}
                              </small>
                            </td>
                            <td class="text-center">{{ inv.day_count }}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">
                              {{ inv.order_unpaid_amount|formatMoney }}
                            </td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                          </tr>
                          <tr v-for="inv in clientInfoApiData.transactions_61_90"
                              :key="inv.id">
                            <td>
                              <b><a href="javascript:;"
                                    @click="onSalesOrderClick(inv)">{{ inv.server_side_code }}</a></b>
                              <br/>
                              <small class="text-inverse">du
                                {{ inv.formatted_creation_date }}
                              </small>
                            </td>
                            <td class="text-center">{{ inv.day_count }}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">
                              {{ inv.order_unpaid_amount|formatMoney }}
                            </td>
                            <td class="text-center">-</td>
                          </tr>
                          <tr v-for="inv in clientInfoApiData.transactions_91_plus"
                              :key="inv.id">
                            <td>
                              <b><a href="javascript:;"
                                    @click="onSalesOrderClick(inv)">{{ inv.server_side_code }}</a></b>
                              <br/>
                              <small class="text-inverse">du
                                {{ inv.formatted_creation_date }}
                              </small>
                            </td>
                            <td class="text-center">{{ inv.day_count }}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">
                              {{ inv.order_unpaid_amount|formatMoney }}
                            </td>
                          </tr>
                          </tbody>
                          <thead>
                          <tr>
                            <th class="text-center" width="15%"></th>
                            <th class="text-center" width="15%">SOUS-TOTAUX</th>
                            <th class="text-center" width="15%">
                              {{ clientInfoApiData.total_0_14|formatMoney }}
                            </th>
                            <th class="text-center" width="15%">
                              {{ clientInfoApiData.total_15_30|formatMoney }}
                            </th>
                            <th class="text-center" width="15%">
                              {{ clientInfoApiData.total_31_60|formatMoney }}
                            </th>
                            <th class="text-center" width="15%">
                              {{ clientInfoApiData.total_61_90|formatMoney }}
                            </th>
                            <th class="text-center" width="15%">
                              {{ clientInfoApiData.total_91_plus|formatMoney }}
                            </th>
                          </tr>
                          </thead>
                        </table>
                      </div>
                      <!-- end table-responsive -->
                      <div class="invoice-price">
                        <div class="invoice-price-right">
                          <small class="f-w-100"><b>COMPTE DEPOT</b></small>
                          <br/>
                          <span class="f-w-600 text-yellow">{{ clientInfoApiData.placement_balance|formatMoney }}</span>
                        </div>
                        <div class="invoice-price-left">

                        </div>
                        <div class="invoice-price-right">
                          <small class="f-w-100"><b>TOTAL DES IMPAYÉS</b></small>
                          <br/>
                          <span class="f-w-600 text-yellow">{{ clientInfoApiData.total_unpaid|formatMoney }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- end invoice-content -->
                    <!-- begin invoice-note --
                    <div class="invoice-note">
                        * Make all cheques payable to [Your Company Name]<br/>
                        * Payment is due within 30 days<br/>
                        * If you have any questions concerning this invoice, contact [Name, Phone
                        Number, Email]
                    </div>
                     end invoice-note -->
                    <!-- begin invoice-footer -->
                    <div class="invoice-footer">
                      <p class="text-center m-b-5 f-w-600">
                        MERCI DE VOTRE FIDELITE
                      </p>
                      <p class="text-center">
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-globe"></i> {{ clientInfoApiData.company_website }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ clientInfoApiData.company_phone }}</span>
                        <span class="m-r-10"><i
                            class="fa fa-fw fa-lg fa-envelope"></i> {{ clientInfoApiData.company_email }}</span>
                      </p>
                    </div>
                    <!-- end invoice-footer -->
                  </div>
                </div>
                <div class="tab-pane fade" v-bind:class="{ 'show active': tab.statement }">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="panel">
                        <div class="panel-body">
                          <div>
                            <form class="form-horizontal form-bordered"
                                  @submit.prevent="getClientStatementData">
                              <div class="form-group row">
                                <div class="col-xs-8">
                                  <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                                     opens="right"
                                                     :ranges="dateRanges"
                                                     :locale-data="{
                                                                format: 'dd-mm-yyyy',
                                                                separator: ' - ',
                                                                applyLabel: 'Appliquer',
                                                                cancelLabel: 'Annuler',
                                                                weekLabel: 'S',
                                                                customRangeLabel: 'Plage Perso.',
                                                                daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                                monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                                firstDay: 1
                                                            }"
                                                     :singleDatePicker="false"
                                                     :timePicker="false"
                                                     :timePicker24Hour="true"
                                                     :showWeekNumbers="false"
                                                     :showDropdowns="false"
                                                     :autoApply="true"
                                                     v-model="form.date_range"
                                                     @update="()=>{}"
                                                     :linkedCalendars="false">
                                    <template v-slot:input="picker" class="text-right">
                                      {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                                      <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                                    </template>
                                  </date-range-picker>
                                </div>
                                <div class="col-xs-2">
                                  <div class="checkbox checkbox-css checkbox-inline">
                                    <input type="checkbox" id="pdf_comp"
                                           v-model="form.to_pdf"/>
                                    <label for="pdf_comp"><i
                                        class="fas fa-download text-warning"></i>
                                      PDF</label>
                                  </div>
                                </div>
                                <!--
                                <div class="col-xs-1">
                                    <div class="checkbox checkbox-css checkbox-inline">
                                        <input type="checkbox" id="excel_comp" v-model="form.to_excel"/>
                                        <label for="excel_comp"><i class="fas fa-download text-warning"></i> Excel</label>
                                    </div>
                                </div>
                                -->
                                <div class="col-xs-2">
                                  <button
                                      :disabled="clientStatementApiDataIsLoading"
                                      type="submit"
                                      class="btn btn-success btn-block">
                                    <i class="fas fa-spinner fa-spin"
                                       v-if="clientStatementApiDataIsLoading"></i>
                                    <i class="fas fa-search" v-else></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="!clientStatementApiDataIsLoading">
                    <div class="invoice" v-if="clientStatementApiData != null">
                      <!-- begin invoice-company -->
                      <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                                <a :href="clientStatementApiData.document_url_pdf" target="_blank"
                                                   class="btn btn-sm btn-white m-b-10 mr-2"
                                                   v-if="clientStatementApiData.document_url_pdf != null"><i
                                                    class="fa fa-file-pdf text-danger fa-fw fa-lg"></i> PDF</a>
                                                <a :href="clientStatementApiData.document_url_excel" target="_blank"
                                                   class="btn btn-sm btn-white m-b-10 mr-2"
                                                   v-if="clientStatementApiData.document_url_excel != null"><i
                                                    class="fa fa-file-excel text-green fa-fw fa-lg"></i> Excel</a>
                                            </span>
                        <h1>Relevé de compte client <br/>
                          <small>{{ clientStatementApiData.period }} |
                            {{ clientStatementApiData.name }}
                          </small>
                        </h1>
                      </div>
                      <!-- end invoice-company -->
                      <!-- begin invoice-header -->
                      <div class="invoice-header">
                        <div class="invoice-from">
                          <small>De chez</small>
                          <address class="m-t-5 m-b-5">
                            <strong class="text-inverse">{{ clientStatementApiData.company_name }}</strong><br/>
                            {{ clientStatementApiData.company_address_line }}<br/>
                            {{ clientStatementApiData.company_cluster_name }},
                            {{ clientStatementApiData.company_location_name }}<br/>
                            Téléphone: {{ clientStatementApiData.company_phone }}<br/>
                          </address>
                        </div>
                        <div class="invoice-to">
                          <small>Pour le client</small>
                          <address class="m-t-5 m-b-5">
                            <strong class="text-inverse">{{ clientStatementApiData.erp_code }}
                              | {{ clientStatementApiData.name }}</strong><br/>
                            {{ clientStatementApiData.address_line }}<br/>
                            {{ clientStatementApiData.cluster_name }},
                            {{ clientStatementApiData.location_name }}<br/>
                            Téléphone: {{ clientStatementApiData.phone }}<br/>
                          </address>
                        </div>
                        <div class="invoice-date">
                          <small>Sur la période</small>
                          <div class="date text-inverse m-t-5">
                            {{ clientStatementApiData.period }}
                          </div>
                          <div class="invoice-detail">
                            COMPTE DEPOT <span
                              class="date text-inverse m-t-5">{{ clientStatementApiData.placement_balance|formatMoney }}</span><br/>
                            SOLDE OUVERTURE <span
                              class="date text-inverse m-t-5">{{ clientStatementApiData.opening_balance|formatMoney }}</span><br/>
                            SOLDE CLOTURE <span
                              class="date text-inverse m-t-5">{{ clientStatementApiData.closing_balance|formatMoney }}</span>
                          </div>
                        </div>
                      </div>

                      <!-- end invoice-header -->

                      <h3>Total {{ clientStatementApiData.transactions_count }} Mouvement(s)</h3>
                      <!-- begin invoice-content -->
                      <div class="invoice-content">
                        <!-- begin table-responsive -->
                        <div class="table-responsive">
                          <table class="table table-invoice">
                            <thead>
                            <tr>
                              <th>DATE</th>
                              <th width="15%">REF DOCUMENT</th>
                              <th>DESCRIPTION</th>
                              <th class="text-center" width="15%">DEBIT</th>
                              <th class="text-center" width="15%">CREDIT</th>
                              <th class="text-right" width="15%">SOLDE</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(transaction,index) in clientStatementApiData.transactions"
                                :key="index">
                              <td>{{ transaction.date }}</td>
                              <td>{{ transaction.ref }}</td>
                              <td>
                                <span class="text-inverse">{{ transaction.short_description }}</span>
                                <!--<br/><small>{{transaction.long_description}}</small>-->
                              </td>
                              <td class="text-center" v-if="transaction.debit > 0">
                                {{ transaction.debit|formatMoney }}
                              </td>
                              <td class="text-center" v-else>{{ '-' }}</td>
                              <td class="text-center" v-if="transaction.credit > 0">
                                {{ transaction.credit|formatMoney }}
                              </td>
                              <td class="text-center" v-else>{{ '-' }}</td>
                              <td class="text-right">{{ transaction.balance|formatMoney }}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                        <div class="invoice-price">
                          <div class="invoice-price-right">
                            <small class="f-w-100"><b>COMPTE DEPOT</b></small>
                            <br/>
                            <span
                                class="f-w-600 text-yellow">{{ clientStatementApiData.placement_balance|formatMoney }}</span>
                          </div>
                          <div class="invoice-price-left">
                            <div class="invoice-price-row">
                              <div class="sub-price">
                                <small>SOLDE OUVERTURE</small>
                                <span
                                    class="text-inverse">{{ clientStatementApiData.opening_balance|formatMoney }}</span>
                              </div>
                              <div class="sub-price">
                                <i class="fa fa-plus text-muted"></i>
                              </div>
                              <div class="sub-price">
                                <small>TOTAL CHIFFRE D'AFFAIRES</small>
                                <span class="text-inverse">{{ clientStatementApiData.total_sales|formatMoney }}</span>
                              </div>
                              <div class="sub-price">
                                <i class="fa fa-minus text-muted"></i>
                              </div>
                              <div class="sub-price">
                                <small>TOTAL RECETTE</small>
                                <span class="text-inverse">{{ clientStatementApiData.total_payment|formatMoney }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="invoice-price-right">
                            <small class="f-w-100"><b>SOLDE DE CLOTURE</b></small>
                            <br/>
                            <span
                                class="f-w-600 text-yellow">{{ clientStatementApiData.closing_balance|formatMoney }}</span>
                          </div>
                        </div>
                      </div>
                      <!-- end invoice-content -->
                      <!-- begin invoice-note --
                      <div class="invoice-note">
                          * Make all cheques payable to [Your Company Name]<br/>
                          * Payment is due within 30 days<br/>
                          * If you have any questions concerning this invoice, contact [Name, Phone
                          Number, Email]
                      </div>
                       end invoice-note -->
                      <!-- begin invoice-footer -->
                      <div class="invoice-footer">
                        <p class="text-center m-b-5 f-w-600">
                          MERCI DE VOTRE FIDELITE
                        </p>
                        <p class="text-center">
                          <span class="m-r-10"><i
                              class="fa fa-fw fa-lg fa-globe"></i> {{ clientStatementApiData.company_website }}</span>
                          <span class="m-r-10"><i
                              class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ clientStatementApiData.company_phone }}</span>
                          <span class="m-r-10"><i
                              class="fa fa-fw fa-lg fa-envelope"></i> {{ clientStatementApiData.company_email }}</span>
                        </p>
                      </div>
                      <!-- end invoice-footer -->
                    </div>
                    <div class="invoice" v-else>
                      <div class="invoice-content">
                        <h1 class="page-header">Relevé de compte client.</h1>
                        <small class="lead text-danger"
                               v-if="clientStatementApiDataErrorMessage != null">
                          {{ clientStatementApiDataErrorMessage }}
                        </small>
                        <small class="lead" v-else>Veuillez définir une période pour optimiser
                          la recherche.
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="invoice" v-else>
                    <div class="invoice-content">
                      <small class="lead"><i class="fas fa-spinner fa-pulse"></i> Chargement en
                        cours ...
                      </small>
                    </div>
                  </div>
                </div>
                <!-- end #profile-friends tab -->
              </div>
              <!-- end tab-content -->
            </div>
          </div>
        </div>
      </div>

      <b-modal
          centered
          hide-header
          hide-footer
          no-close-on-esc
          no-close-on-backdrop
          hide-header-close
          id="salesOrderModal"
          title="Modal Title"
          size="xl"
          @show="()=>{}"
          @hidden="()=>{}">

        <div class="row ">
          <p class="col-12 text-right">
            <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('salesOrderModal')"><i
                class="fa fa-times fa-fw fa-lg"></i></a>
          </p>
        </div>

        <sales-order-component :itemId="selectedSalesOrderId"/>

        <!--
        <div class="row ">
          <p class="col-12 text-right">
            <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
            <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
          </p>
        </div>
        -->
      </b-modal>
    </div>
    <div class="panel-loader" v-else><span class="spinner-small"></span></div>
  </div>
</template>


<script>
import pageApi from '../../store/api/sales/sales-dashboard-page-api'
import SalesOrderComponent from "@/components/app/SalesOrderComponent";

var noPOIMapStyles = [
  {
    featureType: "poi",
    stylers: [
      {visibility: "off"}
    ]
  }
];

export default {
  name: 'client-account-component',
  components: {SalesOrderComponent},
  props: ['itemId'],
  watch: {},
  computed: {},
  methods: {
    //Initialization Methods
    loadPageScripts() {
    },

    //
    show: function (x) {
      this.tab.about = false;
      this.tab.statement = false;
      this.tab.unpaid = false;
      this.tab.orders = false;

      switch (x) {
        case 'about':
          this.tab.about = true;
          break;
        case 'statement':
          this.tab.statement = true;
          break;
        case 'unpaid':
          this.tab.unpaid = true;
          break;
        case 'orders':
          this.tab.orders = true;
          break;
        default:
          this.tab.about = true;
          break;
      }
    },

    formatDateInput (value) {
      if (value) {
        return window.moment(String(value)).format(window.appDefaultDisplayDateTimeFormat)
      }
      return null;
    },

    //
    onSalesOrderClick(salesOrder) {
      this.selectedSalesOrderId = salesOrder.id;
      this.$bvModal.show('salesOrderModal');
    },

    onSalesOrderRowClick(params) {
      // params.row - row object
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument indicates selected or not
      // params.event - click event
      this.selectedSalesOrderId = params.row.id;
      this.$bvModal.show('salesOrderModal');
    },

    //Other Methods
    async getClientInfoData(clientId) {
      this.clientInfoApiDataIsLoading = true;
      this.clientInfoApiDataSuccessMessage = null;
      this.clientInfoApiDataWarningMessage = null;
      this.clientInfoApiDataErrorMessage = null;
      this.clientInfoApiDataValidationErrors = [];
      try {
        const response = await pageApi.getClientInfoData(clientId);
        //console.log(response);
        this.clientInfoApiData = response.data;
        this.clientInfoApiDataIsLoading = false;
        this.clientInfoApiDataSuccessMessage = null;
        this.clientInfoApiDataWarningMessage = null;
        this.clientInfoApiDataErrorMessage = null;
        this.clientInfoApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.clientInfoApiDataIsLoading = false;
          this.clientInfoApiDataSuccessMessage = null;
          this.clientInfoApiDataWarningMessage = null;
          this.clientInfoApiDataErrorMessage = error.response.data.message;
          this.clientInfoApiDataValidationErrors = [];
        } else {
          this.clientInfoApiDataIsLoading = false;
          this.clientInfoApiDataSuccessMessage = null;
          this.clientInfoApiDataWarningMessage = null;
          this.clientInfoApiDataErrorMessage = error.message;
          this.clientInfoApiDataValidationErrors = [];
        }
      }
    },

    async getClientStatementData() {
      this.clientStatementApiData = null;
      this.clientStatementApiDataIsLoading = true;
      this.clientStatementApiDataErrorMessage = null;
      this.clientStatementApiDataValidationErrors = [];
      try {
        const response = await pageApi.getClientStatementData(this.itemId, {
          date_from: this.formatDateInput(this.form.date_range.startDate),
          date_to: this.formatDateInput(this.form.date_range.endDate),
          to_pdf: this.form.to_pdf,
          to_excel: this.form.to_excel
        });
        //console.log(response);
        this.clientStatementApiData = response.data;
        this.clientStatementApiDataIsLoading = false;
        this.clientStatementApiDataErrorMessage = null;
        this.clientStatementApiDataValidationErrors = [];
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.clientStatementApiDataIsLoading = false;
          this.clientStatementApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.clientStatementApiDataValidationErrors = error.response.data.errors;
          } else {
            this.clientStatementApiDataValidationErrors = [];
          }
        } else {
          this.clientStatementApiDataIsLoading = false;
          this.clientStatementApiDataErrorMessage = error.message;
          this.clientStatementApiDataValidationErrors = [];
        }
      }
    },
  },
  data: function () {

    let today = new Date()
    today.setHours(0, 0, 0, 0)
    let todayEnd = new Date()
    todayEnd.setHours(11, 59, 59, 999);
    let yesterdayStart = new Date()
    yesterdayStart.setDate(today.getDate() - 1)
    yesterdayStart.setHours(0, 0, 0, 0);
    let yesterdayEnd = new Date()
    yesterdayEnd.setDate(today.getDate() - 1)
    yesterdayEnd.setHours(11, 59, 59, 999);
    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);

    return {
      appName: window.appConfig.appName,
      appCurrency: window.appConfig.appCurrency,
      intervalId01: 0,

      selectedSalesOrderId:null,

      //
      tab: {
        about: true,
        statement: false,
        unpaid: false,
        orders: false,
      },

      form: {
        date_range: {
          startDate: null,
          endDate: null,
        },
        to_pdf: false,
        to_excel: false,
      },

      map_options: {
        text: 'Default Theme',
        styles: noPOIMapStyles,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullScreenControl: true,
        disableDefaultUi: true
      },

      dateRanges: {
        "Aujourd'hui": [today, todayEnd],
        "Hier": [yesterdayStart, yesterdayEnd],
        "Mois en cours": [thisMonthStart, thisMonthEnd],
        "Mois dernier": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)
        ],
        "Année en cours": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)
        ],
        "Année dernière": [
          new Date(today.getFullYear() - 1, 0, 1),
          new Date(today.getFullYear()- 1, 11, 31, 11, 59, 59, 999)
        ],
      },

      //API Data
      //
      clientInfoApiData: null,
      clientInfoApiDataIsLoading: false,
      clientInfoApiDataSuccessMessage: null,
      clientInfoApiDataWarningMessage: null,
      clientInfoApiDataErrorMessage: null,
      clientInfoApiDataValidationErrors: [],

      //
      clientStatementApiData: null,
      clientStatementApiDataIsLoading: false,
      clientStatementApiDataErrorMessage: null,
      clientStatementApiDataValidationErrors: [],

    };
  },
  created: function () {
    if (this.itemId) {
      this.getClientInfoData(this.itemId);
    }
  },
  mounted: function () {
    this.loadPageScripts();
  },
  beforeDestroy: function () {
  },
}
</script>
